// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD1yCN9nryXa-TmQ7735dbOMUZSCkhCl0g',
  authDomain: 'aumhum-e45f3.firebaseapp.com',
  databaseURL: 'https://aumhum-e45f3.firebaseio.com',
  projectId: 'aumhum-e45f3',
  storageBucket: 'aumhum-e45f3.appspot.com',
  messagingSenderId: '75080491693',
  appId: '1:75080491693:web:0f2b201f9bd6126c1f9c24',
  measurementId: 'G-SQXSC9TX55',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export default app
