import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CHeaderNav, CHeaderToggler } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'
import { AppHeaderDropdown, AppHeaderDropdownNotif } from './header/index'
import { getCustomerDetails } from 'src/services/APIs/Customer/CustomerDetails'
import { getPersonalDetails } from 'src/services/APIs/Customer/PersonalDetails'

import Toggle from 'src/context/ThemeToggle'

const AppHeader = ({ heading, userName }) => {
  const [details, setDetails] = useState()
  const dispatch = useDispatch()

  const theme = useSelector((state) => state.theme)

  theme === 'dark'
    ? document.body.classList.add('dark-theme')
    : document.body.classList.remove('dark-theme')

  const sidebarShow = useSelector((state) => state.sidebarShow)

  const customerDetails = async () => {
    const response = await getCustomerDetails()
    setDetails(response.data)
    sessionStorage.setItem('companyName', response.data.companyName)
  }

  const personalDetails = async () => {
    const response = await getPersonalDetails()
    console.log(response.data)
    setDetails(response.data)
    // sessionStorage.setItem('companyName', response.data.companyName)
  }

  useEffect(() => {
    customerDetails()
    personalDetails()
  }, [])

  const companyName = sessionStorage.getItem('companyName')
  return (
    <header position="sticky" className="mb-4 p-0">
      <CContainer fluid className="dark:bg-bgDark p-0">
        <CHeaderToggler
          className="ps-1 lg:hidden"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>

        <CHeaderNav className="w-full">
          <div className="w-full flex flex-row mt-4 px-16 items-center justify-between">
            <div className="flex xl:flex-row lg:flex-col items-center xl:gap-3">
              <h1 className="text-textHeading dark:text-white font-bold">{companyName}</h1>
              <span className="font-normal text-lg dark:text-white -mb-1">{heading}</span>
            </div>
            <div className="flex flex-row-reverse gap-2 items-center">
              <AppHeaderDropdownNotif />
              <AppHeaderDropdown />
              <Toggle />
            </div>
          </div>
        </CHeaderNav>
        <CHeaderNav></CHeaderNav>
      </CContainer>
    </header>
  )
}

export default AppHeader
