import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarNav } from '@coreui/react-pro'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
// sidebar nav config
import navigation from '../_nav'
import { UserAuth } from 'src/context/AuthContext'
import { useNavigate, Link } from 'react-router-dom'
import logo from 'src/assets/logo_brand.png'

const AppSidebar = () => {
  const { logOut } = UserAuth()
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      await logOut()
      navigate('/login')
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message)
    }
  }
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CSidebar
      style={{ boxShadow: 'inset -2px 0px 12px rgba(39, 4, 139, 0.14)' }}
      position="fixed"
      className="bg-[#E3EBFF] dark:bg-sideBarDark"
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      {' '}
      <Link style={{ textDecoration: 'none' }} to={'/dashboard'}>
        <div className="h-32 w-full my-auto">
          <div className="flex flex-row w-max items-center mx-auto h-full gap-2">
            <img className="h-20 rounded-md" src={logo} />
          </div>
        </div>
      </Link>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
