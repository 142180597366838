import React from 'react'

import axios from 'axios'

export async function getPersonalDetails() {
  const token = sessionStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(
      `https://live.aumhum.xyz/api/secure/bc/details/personal?languageCode=${languageCode}&countryCode=${countryCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function updatePersonalDetails(designation, department, officeLocation) {
  const token = sessionStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  const body = {
    designation: designation,
    department: department,
    officeLocation: officeLocation,
  }
  try {
    const res = await axios.post(
      `https://live.aumhum.xyz/api/secure/professional/profile/update?languageCode=${languageCode}&countryCode=${countryCode}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
