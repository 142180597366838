import React from 'react'
import axios from 'axios'

export async function getCustomerDetails() {
  const token = sessionStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(
      `https://live.aumhum.xyz/api/secure/bc/details?languageCode=${languageCode}&countryCode=${countryCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
