import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CTabContent, CSidebar } from '@coreui/react-pro'

const AppAside = () => {
  const dispatch = useDispatch()
  const asideShow = useSelector((state) => state.asideShow)
  return (
    <CSidebar
      colorScheme="light"
      size="lg"
      overlaid
      placement="end"
      visible={asideShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', asideShow: visible })
      }}
    >
      <CTabContent></CTabContent>
    </CSidebar>
  )
}

export default React.memo(AppAside)
