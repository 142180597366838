import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))

const AddSubscriber = React.lazy(() => import('./views/AddSubscriber/AddSubscriber'))
const AddBulkSubscriber = React.lazy(() => import('./views/AddSubscriber/AddBulkSubscriber'))
const UserProfile = React.lazy(() => import('./views/UserProfile/UserProfile'))
const HelpDesk = React.lazy(() => import('./views/UserProfile/HelpDesk'))
const Org_Goals = React.lazy(() => import('./views/UserProfile/Organization_Goals'))
const BillingAndPlans = React.lazy(() => import('./views/billingAndPlans/billingPlans'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/addSubscriber', name: 'Add Subscriber', exact: true, element: AddSubscriber },
  { path: '/organizationgoals', name: 'Organization Goals', exact: true, element: Org_Goals },
  {
    path: '/addBulkSubscriber',
    name: 'Add Bulk Subscriber',
    exact: true,
    element: AddBulkSubscriber,
  },
  { path: '/profile', name: 'Profile', exact: true, element: UserProfile },
  { path: '/HelpDesk', name: 'Help Desk', exact: true, element: HelpDesk },
  { path: '/billingandplans', name: 'Billing and Plans', exact: true, element: BillingAndPlans },
]

export default routes
