import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilSpeedometer, cilPeople, cilCalendarCheck, cilSettings } from '@coreui/icons'
import { CNavItem } from '@coreui/react-pro'

const _nav = [
  {
    component: CNavItem,
    name: (
      <p className="flex flex-row gap-3 my-0 ml-8 text-iconCol dark:text-white dark:text-white">
        <span>
          <CIcon
            icon={cilSpeedometer}
            ClassName="nav-icon text-iconCol dark:text-white dark:text-white"
          />
        </span>{' '}
        Dashboard
      </p>
    ),
    to: '/dashboard',
  },
  {
    component: CNavItem,
    name: (
      <p className="flex flex-row gap-3 my-0 ml-8 text-iconCol dark:text-white">
        <span>
          <CIcon icon={cilCalendarCheck} ClassName="nav-icon text-iconCol dark:text-white" />
        </span>{' '}
        Organizational Goals
      </p>
    ),
    to: '/organizationgoals',
  },
  {
    component: CNavItem,
    name: (
      <p className="flex flex-row gap-3 my-0 ml-8 text-iconCol dark:text-white">
        <span>
          <CIcon icon={cilPeople} ClassName="nav-icon text-iconCol dark:text-white" />
        </span>{' '}
        Members
      </p>
    ),
    to: '/dashboard#members',
  },

  {
    component: CNavItem,
    name: (
      <p className="flex flex-row gap-3 my-0 ml-8 text-iconCol dark:text-white">
        <span>
          <CIcon icon={cilSettings} ClassName="nav-icon text-iconCol dark:text-white" />
        </span>
        Settings
      </p>
    ),
    to: '/profile',
  },
]

export default _nav
