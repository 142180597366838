import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, BrowserRouter, useParams, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import { AuthContextProvider } from './context/AuthContext'
import ProtectedRoute from './views/pages/login/ProtectedRoute'
import 'react-toastify/dist/ReactToastify.css'
import AuthorityCheck from './views/pages/login/authorityCheck'
import DefaultLayout from './layout/DefaultLayout'
import { ToastContainer } from 'react-toastify'
import { SignIn } from './services/APIs/Login/Login'
import { toast } from 'react-toastify'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
// const ProfileLayout = React.lazy(() => import('./layout/ProfileLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/signIn'))
const Demo = React.lazy(() => import('./views/pages/Demo'))
const ForgotPassword = React.lazy(() => import('./views/pages/login/forgotPassword'))
const ChangePassword = React.lazy(() => import('./views/pages/login/changePassword'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const MobileSignIn = React.lazy(() => import('src/components/SignIn/MobileSignIn.js'))

const App = () => {
  sessionStorage.setItem(
    'languageCode',
    sessionStorage.getItem('languageCode') ||
      (navigator?.language || navigator?.userLanguage).split('-')[0],
  )
  // SETTING LANGUAGE CODE IN SESSION STORAGE
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <AuthContextProvider>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/getDemo" name="Demo Page" element={<Demo />} />
            <Route
              exact
              path="/account/recover/:token"
              name="Change Password"
              token="qwert"
              element={<ChangePassword />}
            />
            <Route
              exact
              path="/forgotPassword"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/403" name="Page 403" element={<AuthorityCheck />} />
            <Route exact path="/MobileSignIn" name="Mobile Sign-in" element={<MobileSignIn />} />
            <Route exact path="/error" name="Page 500" element={<Page500 />} />
            <Route
              path="*"
              name="Home"
              element={
                <ProtectedRoute>
                  <DefaultLayout />
                </ProtectedRoute>
              }
            />
            {/* <Route path="/userProfile/*" name="Profile" element={<ProfileLayout />} /> */}
          </Routes>
        </AuthContextProvider>
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
      />
    </BrowserRouter>
  )
}

export default App
