import React from 'react'
import { AppAside, AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'

const DefaultLayout = () => {
  return (
    <>
      <div className="relative min-h-screen md:flex" data-dev-hint="container">
        <input type="checkbox" id="menu-open" className="hidden" />
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 dark:bg-bgDark">
          <div className="body flex-grow-1">
            <AppContent />
          </div>
          {/* <AppFooter /> */}
        </div>
        <AppAside />
      </div>
    </>
  )
}

export default DefaultLayout
