import React from 'react'
import { Navigate } from 'react-router-dom'
import { UserAuth } from '../../../context/AuthContext'
import PropTypes from 'prop-types'

const ProtectedRoute = ({ children }) => {
  // const { currentUser } = UserAuth()

  const token = sessionStorage.getItem('token')
  const role = sessionStorage.getItem('authorities')
  const role_array = JSON.parse(role)

  if (token === null) {
    console.log(token)
    return <Navigate to="/login" />
  }
  if (!role_array.find((element) => element === 'ROLE_BUSINESS_CUSTOMER')) {
    console.log('found')
    return <Navigate to="/403" />
  }
  return children
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProtectedRoute
